.list-table-sc {
    padding: 50px 30px 0px;
    width: 100%;

    & .sec-title-btn {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        align-items: center;

        & h4 {
            color: #1d3152;
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 800;
        }
    }
    & table tr th {
        color: #1d375f;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
    }

    & table tr:nth-child(01) {
        box-sizing: border-box;
        border-bottom: 1px solid #d2dade;
    }

    & table tr td {
        padding: 7px 0px;
        color: #1d375f;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
    }
}
.ant-table-wrapper {
    & .ant-table-thead {
        & th {
            background-color: #fff;
            border: 0;
            color: #748997;
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 600;
            border-bottom: 1px solid #d2dade;
            padding: 2px 5px;
        }
        & th::before {
            display: none;
        }
    }
    & .ant-table-tbody {
        & td,
        & a {
            border: 0;
            color: #1d3152;
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 500;
            padding: 8px 5px;
            text-decoration: none !important;
        }
        & a:hover {
            color: #246b47;
        }
        & tr:hover {
            & td {
                border: 0;
                background-color: transparent;
            }
        }
        & tr:first-child {
            padding-top: 10px;
        }
    }
    & .ant-table-pagination.ant-pagination {
        display: none;
    }

    & .ant-table-empty {
        & td.ant-table-cell {
            color: #a7b9c5;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 800;
            padding-top: 25px;
        }
    }
}
buttons {
    &.icon-btn {
        display: flex;
        align-items: center;
        transition: all ease-in-out 0.3s;
    }
    &.icon-btn svg {
        font-size: 17px;
        margin-right: 5px;
    }
    &.d-btn.green.icon-btn:hover {
        background-color: #1d3152;
    }
}
.LDA-no-text {
    color: #748997;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin-top: 25px;
    width: 100%;
    text-align: center;
}
.tblResponsive {
    position: relative;
    display: block;
    height: calc(100vh - 187px) !important;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #1d3152;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
@media (max-width: 500px) {
    .list-table-sc .sec-title-btn {
        display: block;
        text-align: center;
    }
    buttons.icon-btn {
        display: inline-block;
    }
}
