$db-clr: #1d3152;

ul.ls-cai,
ul.ls-pai {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
}

.ls-breadcrumbs {
    position: relative;
    text-transform: uppercase;
    font-family: inherit;
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 20px;
}
.ls-breadcrumbs a {
    color: #748997;
    font-family: "Montserrat Bold";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 32px;
}

.ls-title {
    margin-bottom: 20px;
}

h3.d-title {
    color: #1d3152;
    font-family: "Montserrat ExtraBold";
    font-size: 28px;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 30px;
}

ul.ls-cai {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 15px;

    & li {
        position: relative;
        line-height: 1.5;

        & a {
            position: relative;
            display: inline-block;
            text-decoration: none;
            color: #a7b9c5;
            text-transform: capitalize;
            font-family: Montserrat;
            font-size: 17px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 18px;

            & a:hover {
                color: $db-clr;
            }
        }
        & a.active {
            color: #1d3152 !important;
        }
    }
}

.font-weight-normal:hover {
    font-weight: bold !important;
}
.font-weight-normal {
    font-weight: normal !important;
}

.ls-pa {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #a7b9c5;
    font-size: 17px;
    text-transform: capitalize;
    line-height: 18px;
    margin-bottom: 10px;
    font-weight: 600;

    & svg {
        margin: 0;
        display: inline-block;
    }
}

li.ls-pa {
    margin-bottom: 0px;

    & a.active {
        & span {
            color: #1d3152;
        }
    }
    & span {
        display: inline-block;
        margin-bottom: 7px;
        color: #a7b9c5;
        font-family: Montserrat;
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }
}
a.active li.ls-pa span {
    color: #1d3152 !important;
}

// ul.ls-pai li ul {
//   display: none;
// }
// ul.ls-pai li.active ul {
//   display: block;
// }
ul.ls-pai li a:hover {
    color: $db-clr;
}

// Drop

ul.ls-pai > a {
    position: relative;
    display: block;
    transition: 10s;
    text-decoration: none;

    & span {
        color: #a7b9c5;
        font-family: "Montserrat Bold";
        font-size: 17px;
        letter-spacing: 0;
        line-height: 20px;
    }

    & svg {
        color: #a7b9c5;
    }

    .ls-pa {
        display: none;
    }

    & ul.ls-cai {
        position: relative;
        display: block;
        transition: max-height 0.25s ease-in;
        height: 0;
        // width: 200px;
        // text-overflow: ellipsis;
        // overflow: hidden;

        & a {
            position: relative;
            transition: 0.4s;
            text-decoration: none;
            display: inline-block;
            margin-bottom: 7px;
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #1d3152;
            font-family: "Montserrat Regular";
            font-size: 17px;
            letter-spacing: 0;
            line-height: 20px;
        }
    }
}
ul.ls-pai > a.active ul.ls-cai {
    // height: 100%;
    transition: max-height 0.25s ease-in;
    height: auto;
}

ul.ls-pai > a svg {
    transform: rotate(0deg);
    transition: 0.4s;
}
ul.ls-pai > a.active svg {
    transform: rotate(-180deg);
    color: #1d3152;
    transition: 0.4s;
}
.ls-breadcrumbs a:hover {
    color: #246b47;
}
.list-table-sc table td a {
    text-decoration: none;
    color: black;
    transition: 0.4s;
}

.list-table-sc table td a:hover {
    color: blue;
}
ul.ls-pai > a.active li.ls-pa {
    display: block;
}

ul.ls-pai > a.active {
    position: relative;
    display: block;
    transition: 10s;
    text-decoration: none;

    & span {
        color: #1d3152;
    }

    & svg {
        color: #1d3152;
    }
}

li.ls-pa {
    position: relative;
}

ul.ls-cai {
    max-height: 100vh !important;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #1d3152;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

@media (max-width: 991px) {
    h3.d-title {
        font-size: 24px;
    }
    li.ls-pa span {
        font-size: 15px;
    }
    ul.ls-cai li a {
        font-size: 15px;
    }
}
