/* make the customizations */
$theme-colors: (
    "info": tomato,
    "danger": teal
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

ul.profile-dropdown {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;

    & li {
        & span {
            position: relative;
            display: inline-block;
            margin-right: 10px;
        }
        & img {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            object-fit: cover;
            object-position: top center;
            overflow: hidden;
        }
    }
    & .logout-container {
        padding: 10px;
        padding-right: 10px;
        padding-left: 10px;
        background-color: #246B47;
        border-radius: 5px;
        cursor: pointer;
    }
}
.company-logo {
    height: 50px;
    width: 50px !important;
    border-radius: 50px;
}