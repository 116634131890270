/* make the customizations */
$theme-colors: (
    "info": tomato,
    "danger": teal
);


/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

// Genral
$lw-clr : #748997;
$w-clr : #ffffff;
$position-pr : relative;
$small: 300px;
$medium: 991px;

.container-fluid {
    padding: 0px 40px !important;
}

section.header ul {
    list-style: none;
}

ul.head-ul {
    position: $position-pr;
    padding: 0;
    margin: 0;
    line-height: normal;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: calc(100% - 90px);
    margin-left: auto;
    width: 100%;
    
    & li{
        position: $position-pr;
    }
}

//  Main Header

section.header {
    position: $position-pr;
    background-color: #1D3152;
    color: white;

    & .row{
        align-items: center;
        min-height: 75px;
    }
}

// Header Logo
.main-logo {
    position: $position-pr;
    width: auto;
    height: auto;
    display: inline-block;
    float: left;
    margin: 0;
    top: 10px;

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

ul.head-nav {
    position: $position-pr;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px 30px;
    
    & li {
        position: $position-pr;
        display: inline-block;
        line-height: 1;

        & a {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            text-transform: uppercase;
            text-decoration: none;
            transition: 0.4s;
            color: #748997;
            font-family: Montserrat;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 32px;

            & svg{
                margin-right: 7px;
                width: 20px;
                height: 22px;
            }
        }
        & a:hover, a.active{
            color: $w-clr;
        }
    }
}

// For Hamburg
.head-nav-hamburger {
    display: none;
}

.head-nav-main{
    //some CSS
    @media (max-width: $small) {
      //do Smth
    }
  }
@media (max-width: $medium) {
    section.header {
        margin-bottom: 0px;
    }
    ul.head-nav {margin-left: 0;gap: 10px;}

    ul.head-nav li a {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    ul.head-ul {
        display: none;
    }
    .main-logo {
        top: 0px;
    }
}