// Genrel
$d-blur-clr : #1D3152;
$medium: 991px;

.ws-box {
    position: relative;
    
    & img {
        position: absolute;
        left: -70px;
        top: -27px;
        width: 60px;
        height: 60px;
    }
    & .d-btn {
        position: relative;
        display: inline-block;
        text-decoration: none;
        background-color: #246B47;
        padding: 10px 15px;
        border-radius: 4px;
        color: #FFFFFF;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 13px;
        margin-top: 10px;
    }
}

.welcome-screen {
    position: relative;
    padding-top: 75px;
    text-align: left;

    & h1 {
        text-transform: capitalize;
        margin-bottom: 20px;
        color: #1D3152;
        font-family: Montserrat;
        font-size: 37px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 40px;

    }
    & p {
        color: $d-blur-clr;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
    }
}
@media (max-width: $medium) {                                                           
    .welcome-screen {
        padding-top: 80px;
        & h1 {
            font-size: 30px;
            margin-bottom: 10px;
        }
        p {
            font-size: 15px;
        }
    }
    .ws-box img {
        position: absolute;
        left: 0;
        top: -65px;
    }
}
@media (max-width: 767px) {
    .welcome-screen * {
        text-align: center;
    }
    .ws-box img {
        left: 50%;
        transform: translateX(-50%);
    }
    .ws-box .d-btn{
        margin: 0 auto;
    }
}
@media (max-width: 579px) {
    .welcome-screen h1 {
        font-size: 25px;
        line-height: 1;
    }
    .welcome-screen p {
        font-size: 14px;
    }
}
@media (max-width: 499px) {
    .welcome-screen h1 {
        font-size: 18px;
    }
    .welcome-screen p {
        font-size: 13px;
    }
}
.loading{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.websiteTitle {
    margin-bottom: 5px;

    & strong {
        display: inline-block;
        margin-right: 5px;
    }
}