body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Montserrat */
@font-face {
  font-family: "Montserrat Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat"), url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat"), url("./fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat"), url("./fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat"), url("./fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat"), url("./fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}

/* Mulish */
@font-face {
  font-family: "Muli Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Muli"), url("./fonts/Muli/Mulish-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Muli Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Muli"), url("./fonts/Muli/Mulish-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Muli SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Muli"), url("./fonts/Muli/Mulish-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Muli Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Muli"), url("./fonts/Muli/Mulish-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Muli ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("Muli"), url("./fonts/Muli/Mulish-ExtraBold.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.ant-message-custom-content {
  display: flex;
  align-items: center;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");

.error-403-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.error-403-img-container h3 {
  font-size: 30px;
  font-weight: 600;
  margin-top: 15px;
}

.error-403-img-container p {
  font-size: 15px;
  font-weight: 500;
}

.error-403-img-container button {
  text-transform: uppercase;
  background: #246b47;
  color: #fff;
  border: none;
  font-size: 12px;
  padding: 6px 15px;
}
