.inbox-wrapper {
    height: calc(100vh - 75px);
    & .auto-sec {
        // height: calc(100% - 75px);
        overflow: hidden;
        min-height: inherit;
    }
}
.inbox-msgs{
    word-break: break-word;
}

// Inbox Sidebar Header
.inbox-sidebar-wrapper {
    position: relative;
    background-color: #f6f7f8;
    box-shadow: 0 2px 11px 1px rgba(0, 0, 0, 0.18);
    width: 440px;
    min-height: calc(100vh - 75px);
    // height: 100%;
    // overflow-y: scroll;
    display: flex;
    flex-direction: column;
    transition: 0.4s all;
    z-index: 10;
    overflow: hidden;

    /* width */

    & .inbar-header {
        display: flex;
        flex-direction: row;
        // padding: 35px;
        // padding-bottom: 0;
        padding: 20px 20px 0 20px;
        justify-content: space-between;
        align-items: center;

        & a.automations-link {
            color: #748997;
            font-family: "Montserrat Bold";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 32px;
            margin-bottom: 4px;
            text-decoration: underline;
        }
        & a.automations-link:hover {
            color: #246b47;
        }

        & a.ant-dropdown-trigger.ant-dropdown-link {
            position: relative;
            color: #1d3152;
            font-family: Montserrat;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            text-decoration: unset;
            margin: 0 -20px;
            padding-left: 20px;
            height: 53px;
            padding-top: 13px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover {
                border-radius: 4px;
                background-color: #ffffff;
                box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 0.18);
            }

            & svg {
                margin-left: 10px;
            }

            & span {
                // word-break: none;
            }
        }
    }
    & .ant-select-selector {
        border: 0px !important;
        background: transparent !important;
        color: #1d3152;
        font-family: Montserrat;
        text-decoration: unset;
        padding: 0px !important;
        // height: 53px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0px !important;
        width: 100% !important;
        box-shadow: none !important;
    }

    & .ant-select-selector span.ant-select-selection-item {
        font-size: 18px;
        font-family: "Montserrat Bold" !important;
        letter-spacing: 0;
        line-height: 24px;
    }
}
.ant-select-dropdown {
    box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 18%);
    border-radius: 0px 0px 4px 4px;
    left: 15px !important;
    & .ant-select-item-option-content {
        color: #1d3152;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
    }
    & .ant-select-item {
        margin-top: 10px;
        padding-left: 46px;
        background-color: transparent !important;
    }
    // & .ant-select-item-option-selected:before {
    //     content: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path></svg>');
    //     color: #3AAF50;
    // }
    & .ant-select-item-option-selected:before,
    & .ant-select-item-option-active:before {
        content: "";
        color: #3aaf50 !important;
        font-size: 28px;
        background: url('data:image/svg+xml;utf8,<svg stroke="rgb(58 175 80)" fill="rgb(58 175 80)" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path></svg>');
        height: 20px;
        width: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: 20px;
    }
}
.ant-select {
    margin: 0 -20px;
    padding-left: 17px;

    & .ant-select-arrow {
        color: #1d3152;
    }
}
.ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open
    .ant-select-selector {
    border: 0px !important;
    box-shadow: none !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
    color: #1d3152 !important;
}
.ant-select.ant-select-open {
    background-color: #fff !important;
    border-radius: 10px !important;
    background-color: #ffffff !important;
    box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 18%) !important;
    outline: none !important;
    border: 0px !important;
}
.ant-dropdown-menu-title-content {
    flex: auto;
    color: #1d3152;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    padding: 12px;
}

// Inbox Sidebar Tabs

.inbar-tabs {
    display: flex;
    flex-direction: column;
    & .chat-listing {
        /* width */
    &::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #1d3152;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #616e83;
    }
    }
    & .chat-listing a {
        display: block;
        text-decoration: none;
        transition: all ease-in-out 0.3s;
    }
    & .no-data {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }

    & .ant-spin-dot-item {
        background-color: #1d3152 !important;
    }
    & .ant-spin {
        width: 100%;
        display: block;
    }
    & .ant-tabs-top > .ant-tabs-nav,
    .ant-tabs-bottom > .ant-tabs-nav,
    .ant-tabs-top > div > .ant-tabs-nav,
    .ant-tabs-bottom > div > .ant-tabs-nav {
        margin: 0 0 0px 0;
    }

    & .tabs-bottom-color {
        height: 24px;
        background-color: #dde4e7;
        margin: 0 -15px 15px -15px;
    }

    & .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
        width: 100%;
    }

    & .ant-tabs-tab {
        width: 50%;
        text-align: center;
        justify-content: center;
        border-bottom: 1px solid #748997;

        & .ant-tabs-tab-btn {
            color: #748997;
            font-family: "Montserrat Medium";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 32px;
        }

        &.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #1d3152;
        }
    }

    & .ant-tabs-ink-bar {
        background: #246b47;
    }

    & .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
        height: 6.22px;
        border-radius: 3px;
    }

    & .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 0px;
    }

    & .ant-tabs-content-holder {
        padding: 12px;
        padding-top: 0;
        overflow: hidden;
        // overflow-y: auto;
        // height: calc(100vh - 272px);

        /* width */
        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #1d3152;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #616e83;
        }
        & .msg-loadig {
            text-align: center;
            margin-bottom: 5px;
        }
    }

    & .inbar-search {
        margin-bottom: 15px;
        position: relative;

        & input[type="text"] {
            border: 0.5px solid #d2dade;
            border-radius: 4px;
            background-color: #ffffff;
            width: 100%;
            height: 36px;
            padding-left: 30px;
            color: #a7b9c5;
            font-family: Montserrat;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
        }

        & svg {
            height: 15.1px;
            width: 15.46px;
            fill: #748997;
            position: absolute;
            top: 12px;
            left: 10px;
            cursor: pointer;
        }
    }

    & .inbar-msg-card {
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #d2dade;
        border-radius: 4px;
        background-color: #f6f7f8;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 80px;
        transition: all ease-in-out 0.3s;

        & .inmsg-card-left {
            flex: 1;
            margin-right: 10px;
        }

        & .inmsg-card-left .inmsg-title {
            color: #1d3152;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 17px;
            font-family: "Muli SemiBold" !important;
            margin-bottom: 5px;
            transition: all ease-in-out 0.3s;
        }

        & .inmsg-card-left p {
            color: #1d3152;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            transition: all ease-in-out 0.3s;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding: 2px;
            min-height: 30px;
        }

        & .inmsg-card-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            max-width: 30%;

            & .unread-tag {
                width: 13px;
                height: 13px;
                background: #d31a1a;
                border-radius: 50%;
                margin-right: 5px;
            }

            & .unread-time {
                font-family: "Muli ExtraBold" !important;
                color: #3aaf50 !important;
            }

            & .read-time {
                font-family: "Muli Regular" !important;
            }

            & .display-inline {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
            }

            & .inmsg-badge {
                color: #246b47;
                font-family: "Montserrat Medium";
                font-size: 9px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 10px;
                text-align: center;
                border: 1.3px solid #133d28;
                border-radius: 4px;
                padding: 2px 3px;
                transition: all ease-in-out 0.3s;
                word-break: break-word;
            }

            & time {
                color: #344c60;
                font-size: 10px;
                letter-spacing: 0;
                line-height: 11px;
                text-align: right;
                transition: all ease-in-out 0.3s;
            }
        }
    }

    .chat-listing a:hover {
        & .inbar-msg-card {
            background-color: #f5fbf6;
            border-color: #3aaf50;
        }
        & .inbar-msg-card h5.inmsg-title {
            color: #1d3152;
        }
        & .inbar-msg-card p {
            color: #1d3152;
        }
        & .inbar-msg-card .inmsg-card-right .inmsg-badge {
            color: #1d3152 !important;
            border-color: #1d3152;
        }
        & .inbar-msg-card time {
            color: #1d3152;
        }
    }
    .chat-container {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.chat-listing a.active-chat {
    & .inbar-msg-card {
        background-color: #f5fbf6;
        border-color: #3aaf50;
    }
    & .inbar-msg-card h5.inmsg-title {
        color: #1d3152;
    }
    & .inbar-msg-card p {
        color: #1d3152;
    }
    & .inbar-msg-card .inmsg-card-right .inmsg-badge {
        color: #246b47 !important;
        border-color: #1d3152;
    }
    & .inbar-msg-card time {
        color: #1d3152;
    }
}

.chat-listing {
    & .inbar-msg-card .inmsg-card-right .inmsg-badge {
        color: #246b47 !important;
        border-color: #1d3152;
        background-color: #f5fbf6;
    }
}

// Inbox Main Panel
.inbox-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    border-bottom: 1px solid #d2dade;

    & .ih-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.4s;

        & .badge {
            background-color: #3aaf50;
            margin-left: 20px;
            width: 40px;
            height: 17px;
            font-family: "Muli SemiBold";
            font-size: 11px;
            padding: 0;
            line-height: 16px;
            font-weight: 400;
        }
        & .badge-green {
            background-color: #3aaf50;
        }
        & .badge-red {
            background-color: #c22a2a;
        }

        & h3 {
            color: #1d3152;
            font-family: "Montserrat ExtraBold";
            font-size: 20px;
            letter-spacing: 0;
            line-height: 22px;
            margin-bottom: 0;
        }
    }

    & .ih-right svg {
        box-shadow: 0 0 2px 0 #fffefa;
        fill: #1d3152;
        height: 21.51px;
        width: 21.51px;
    }
    & .resolveBtn {
        margin-right: 30px;
        border-radius: 10%;
        background-color: #1d3152;
        color: #fff;
        border: none;
    }
    & .deselectBtn{
        margin-right: 20px;
        border-radius: 5px;
        background-color: #1D3152;
        color: #FFF;
        padding: 0px 10px;
        border: none;
    }
}

.inbox-body {
    display: block;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 15px 25px 25px 25px;
    height: calc(100vh - 101px);
    overflow: hidden;
    overflow-y: auto;

    & .unread-msg-panel {
        // display: flex;
        // flex-direction: row;
        // justify-content: center;

        & .unread-msg-bar {
            border-radius: 4px 4px 0 4px;
            // background-color: #ebf7ed;
            display: flex;
            align-items: center;
            padding: 9px 10px;
            max-width: 100%;
            font-size: 14px;
            text-align: center;
            color: #3aaf50;
            font-family: "Montserrat";
            font-weight: 500;
        }
        & .unread-msg-bar::before,
        & .unread-msg-bar::after {
            content: '';
            flex: 1;
            border-bottom: 1px solid #3aaf50;
        }

        & .unread-msg-bar:not(:empty)::before {
             margin-right: .25em;
        }

        & .unread-msg-bar:not(:empty)::after {
             margin-left: .25em;
        }
    }

    /* width */
    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #1d3152;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #616e83;
    }
    & .msg-loadig {
        text-align: center;
        margin-bottom: 5px;
    }

    & .ib-date {
        text-align: center;

        & p {
            color: #a7b9c5;
            font-family: "Montserrat Medium";
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 11px;
            text-transform: uppercase;
        }
    }

    & .ib-panel {
        display: flex;
        width: 100%;
        align-items: flex-start;
        flex-wrap: unset;
        margin-bottom: 20px;

        & .ib-panel-msg {
            border-radius: 4px 4px 0 4px;
            background-color: #ebf7ed;
            display: flex;
            align-items: center;
            padding: 9px 10px;
            max-width: 90%;
            font-family: "Montserrat Medium";
        }

        & .ib-panel-img {
            border-radius: 4px 4px 0 4px;
            display: flex;
            align-items: center;
            padding: 9px 10px;
            max-width: 90%;
            align-items: flex-end;
        }
        & p {
            margin-bottom: 0;
            color: #1d3152;
            font-family: "Montserrat";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
        }

        & .ib-panel-name {
            height: 32.63px;
            width: 32.63px;
            background-color: #246b47;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 32px;
            border-radius: 50%;
        }

        & .ib-panel-date {
            color: #a7b9c5;
            font-family: "Montserrat Medium";
            font-size: 10px;
            letter-spacing: 0;
            line-height: 11px;
            margin-top: 7px;
        }

        & .ib-panel-automations {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 10px;

            & .ib-badge {
                border-radius: 12.5px;
                background-color: #246b47;
                color: #ffffff;
                font-family: Montserrat;
                font-size: 10px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 11px;
                padding: 7px 10px;
                margin-left: 7px;
            }
        }
    }
}

.ib-panel.ib-right-panel {
    justify-content: flex-end;
    flex-direction: row-reverse;

    & .ib-panel-name {
        margin-left: 20px;
        margin-top: 2px;
    }

    & .ib-panel-body {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: calc(100% - 32.63px);
    }
}

.ib-panel.ib-left-panel {
    & .ib-panel-msg {
        background-color: #dee4e7;

        & p {
            color: #344c60;
        }
    }

    & .ib-panel-name {
        background-color: #1d3152;
        margin-right: 20px;
        margin-top: 2px;
    }

    & .ib-panel-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% - 32.63px);
    }
}

//Inbox Right Panel
.inbox-main-panel {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    // transition: 0.4s;
}

.inbox-right-panel {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 321px;
    width: 100%;
    border-left: 1px solid #d2dade;
    transition: 0.4s;
    margin-right: -321px;

    & .inputField{
        outline: "none";
        border: "none";
        background-color: "transparent";
        font-family: 'Montserrat Medium';
        padding: 5px 0px;
    }

    & .inputFieldError {
        border: solid;
        border-width: 1px;
        color: #cc0000;
        padding: 5px 0px;
    }

    & .action-btn {
        display: "flex";
        align-items: "center";
        border-radius: 10%;
        margin-left: 5px;
        margin-top: 5px;
        padding: 5px;
        background-color: #D3D3D3;
        color: "black";
        border: "none";
        cursor: "pointer";
    }

    & .inp-box.inp-header-box {
        background-color: #f6f7f8;
    }

    & .inp-box:not(:last-child) {
        border-bottom: 1px solid #d2dade;
    }

    & .inp-box {
        display: flex;
        flex-direction: column;
        padding: 30px;
        background-color: white;

        &.views-box .inp-box-cont a:not(:last-child) {
            margin-bottom: 5px;
        }

        &.conversations-box .inp-box-cont a:not(:last-child) {
            margin-bottom: 5px;
        }

        & h3 {
            color: #1d3152;
            font-family: "Montserrat ExtraBold";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 20px;
            margin-bottom: 15px;
        }

        & .inp-box-cont:not(:last-child) {
            margin-bottom: 15px;
        }

        & .inp-box-cont {
            display: flex;
            flex-direction: column;

            & label {
                color: #748997;
                font-family: "Montserrat Bold";
                font-size: 14px;
                letter-spacing: 0;
                line-height: 16px;
                margin-bottom: 10px;
            }

            & a,
            & span {
                color: #1d375f;
                font-family: "Montserrat Medium";
                font-size: 14px;
                letter-spacing: 0;
                line-height: 16px;
                display: block;
                text-decoration: unset;
            }

            & p {
                color: #1d3152;
                font-family: "Montserrat Medium";
                font-size: 14px;
                letter-spacing: 0;
                line-height: 16px;
                margin-bottom: 0;
            }
            & p.pageVisited {
                word-break: break-word;
            }
        }
    }
}

.inbox-right-panel.show {
    margin-right: 0px;
    z-index: 10;
    & .inner-right-panel {
        overflow-y: auto;
    }
}

.ih-right.toggle {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: -40px;
    top: 24px;
    font-size: 22px;
    z-index: 99;
    width: 22px;
    height: 22px;
    overflow: hidden;
    transition: 0.5s;

    &:hover {
        cursor: pointer;
    }

    & img {
        width: 100%;
        height: 100%;
        transition: 0.5s;
    }
}

.inbox-right-panel.show .ih-right.toggle img {
    transform: rotate(-180deg);
}

.ih-right.toggle svg {
    border: 2px solid white;
    border-radius: 100px;
    background-color: white;
}

button.inbox-sidebar-hamburger {
    position: absolute;
    right: -58px;
    top: 20px;
    z-index: 10;
    border-radius: 5px;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    width: 30px;
    height: 30px;
    background-color: #1d3152;
    color: white;
    box-sizing: border-box;
    border: 1px solid #ffffff;
}

.auto-sec .inner-sec-w-side {
    position: relative;
    z-index: 9;
}

.inbox-footer {
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    border-top: 1px solid #a7b9c5;
    border-radius: 0px;
    background-color: #ffffff;

    & .inbox-footer-input {
        box-sizing: border-box;
        height: 40px;
        width: 100%;
        border: 1px solid #a7b9c5;
        border-radius: 10px;
        background-color: #ffffff;
        padding: 0px 0px 0px 15px;

        & input {
            border: 0px;
            width: calc(100% - 57px);
            height: 100%;
            color: #1d3152;
            font-family: inherit;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 17px;
            border-radius: 20px;

            &::placeholder {
                color: #a7b9c5;
            }
        }
        & button {
            height: 100%;
            width: 50px;
            background-color: white;
            border: 0px;
            font-size: 14px;
            font-weight: 600;
            color: #a7b9c5;
        }
    }
    & ul {
        position: relative;
        list-style: none;
        padding: 0;
        margin: 4px 0 0 25px;

        & li {
            position: relative;
            display: inline-block;
            margin-right: 30px;
            line-height: 1;

            & span {
                font-size: 17px;
                color: #a7b9c5;
            }
        }
    }
}

@media (max-width: 1440px) {
    .inbox-sidebar-wrapper {
        & .inbar-header {
            & a.ant-dropdown-trigger.ant-dropdown-link {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 1200px) {
    .inbox-sidebar-wrapper {
        & .inbar-header {
            & a.ant-dropdown-trigger.ant-dropdown-link {
                font-size: 16px;
            }
        }
    }

    .inbox-main-wrapper {
        & .inbox-right-panel {
            & .inp-box {
                & h3 {
                    font-size: 16px;
                }
            }
        }
    }

    .inbox-body {
        .ib-panel {
            p {
                font-size: 14px;
            }
        }
    }

    .inbar-tabs {
        .inbar-msg-card {
            .inmsg-card-left {
                .inmsg-title {
                    font-size: 14px;
                }
            }
        }
    }

    .inbar-tabs {
        .inbar-msg-card {
            .inmsg-card-left {
                p {
                    font-size: 13px;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .inbox-sidebar-wrapper {
        width: 350px;
    }
}

@media (max-width: 991px) {
    .inbox-right-panel {
        position: absolute !important;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .inbox-sidebar-wrapper {
        position: absolute;
        transition: 0.4s;
        margin-left: -360px;
    }

    button.inbox-sidebar-hamburger {
        display: flex;
    }

    .inbox-sidebar-wrapper.show {
        margin-left: 0px;
    }

    .inbox-header .ih-left {
        margin-left: 40px;
    }
}

@media (max-width: 500px) {
    .ib-panel-body {
        width: calc(100% - 62.63px);
    }

    .inbox-body .ib-panel .ib-panel-automations .ib-badge {
        line-height: 12px;
        padding: 5px 5px;
        border-radius: 6.5px;
    }

    .inbox-header .ih-left h3 {
        font-size: 14px;
    }

    .inbox-header .ih-left .badge {
        margin-left: 10px;
    }

    .inbox-sidebar-wrapper.show button.inbox-sidebar-hamburger {
        right: 10px;
        top: 10px;
    }

    .inbox-right-panel.show .ih-right.toggle {
        left: unset;
        top: 10px;
        right: 10px;
    }

    .inbox-sidebar-wrapper.show,
    .inbox-right-panel.show {
        max-width: 100% !important;
        width: 100% !important;
        z-index: 100;
    }
}
.inbox-loader-top {
    width: calc(100% - 440px);
    display: flex;
    align-items: center;
    justify-content: center;

    & .ant-spin-dot-item {
        background-color: #1d3152 !important;
    }
}
section.blankInbox {
    width: calc(100% - 440px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Montserrat;
    & img {
        width: 100px;
    }
}
.ant-spin-dot-item {
    background-color: #1d3152 !important;
}
@keyframes mercuryTypingAnimation {
    0% {
        -webkit-transform: translateY(0px);
    }
    28% {
        -webkit-transform: translateY(-5px);
    }
    44% {
        -webkit-transform: translateY(0px);
    }
}
.msg-typing {
    border-radius: 15px !important;
    padding: 10px 16px !important;
    margin-left: 50px;
    & .tiblock {
        align-items: center;
        display: flex;
        height: 17px;

        & .tidot {
            background-color: #a7b9c5;
        }
        & .tidot {
            animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
            border-radius: 50px;
            display: inline-block;
            height: 8.47px;
            width: 8.47px;
        }
        & .tidot:not(:last-child) {
            margin-right: 5px;
        }
        & .tidot:nth-child(1) {
            animation-delay: 200ms;
        }
        & .tidot:nth-child(2) {
            animation-delay: 300ms;
        }
        & .tidot:nth-child(3) {
            animation-delay: 400ms;
        }
    }
}
.automation-media-container {
    display: flex;
    justify-content: flex-end;
}

.center-spiner {
    display: flex;
    justify-content: center;
    align-items: center;
}
.msg-loader-height {
    height: calc(100vh - 420px);
}
.cursor {
    cursor: pointer;
    margin-bottom: 5px;
}
.inputField {
    outline: none;
    border: none;
    border-radius: 2px;
    background-color: transparent;
    padding: 5px 0px;
}

.inputField:focus {
    background-color: #fff;
}

.inputFieldError {
    border: solid;
    border-width: 1px;
    border-radius: 5px;
    color: #cc0000;
    padding: 5px 0px;
}
.flexEnd {
    display: flex;
    justify-content: end;
}
.action {
    display: flex;
    align-items: center;
    border-radius: 10%;
    margin-left: 5px;
    margin-top: 5px;
    padding: 5px;
    background-color: #D3D3D3;
    color: black;
    border: none;
    cursor: pointer;
}
.pd5 {
    padding: 5px;
}
.mt10 {
    margin-top: 10px;
}
.error {
    color: #cc0000;
    font-size: 12px;
}
.name {
    font-family: "Montserrat ExtraBold";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    outline: none;
    padding: 5px 0px;
    border: none;
    border-width: 0px;
    background-color: transparent;
}

.name:focus {
    border-width: 0;
    border-bottom: 1px solid #1d3152 !important;
    box-shadow: none !important
}

.background-white {
    background-color: #fff;
}
.custom-icon-styling {
    transform: rotate(180deg);
}
.ant-select-open .custom-icon-styling {
    transform: rotate(0deg);
}
.width-100 {
    width: 100%;
}
.field-container {
    display: flex;
    align-items: center;
}
.special-label {
    content-visibility: hidden;
    display: none;

}
.muli-regular {
    font-family: "Muli Regular";
}
.montserrat-medium {
    font-family: "Montserrat Medium" !important;
}
.circle {
    width: 38px;
    height: 40px;
    background-color: #353535;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 50%;
}
.cursor {
    cursor: pointer
}
.customer-popover {
    margin: -12px -16px;
    padding: 7px 0;
    list-style: none;
    display: grid;
    font-family: "Montserrat Regular";
}
.customer-popover li {
    cursor: pointer;
    padding: 6px 15px !important;
}
.customer-popover li:hover,
.customer-popover li:focus {
    background-color: #eeeeee;
}
.menu-icon {
    width: 20px;
    height: 20px;
    color: rgb(112, 112, 112);
}
.margin-left-10 {
    margin-left: 10px;
}
.margin-right-10 {
    margin-right: 10px;
}
.close-chat:hover span {
    display: none;
}
.close-chat:hover:after {
    content: "Close";
    // padding: 0 0 0 50px;
    padding: 0;
}

.pencil-rotate {
    transition: 1s;
    transform: rotateY(180deg);
}
.cancel-rotate {
    transition: 1s;
    transform: rotateY(0deg);
}
.margin-left-5 {
    margin-left: 5px;
}
.country-code-prefix {
    border: 2px solid transparent;
    padding: 2px 0 4px 3px;
    border-right-width: 0;
    border-right-width: 0;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

}
.country-code-prefix-selected {
    background: white;
    box-shadow: inset 0 0 0 rgb(58 175 80), -1px 0 0 1px rgb(58 175 80) !important;
}
.custom-input input:focus {
    outline: none !important;
    box-shadow: inset 0 0 0px rgb(58 175 80), 1px 0 0px 1px rgb(58 175 80) !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

 .ant-select-dropdown{
    width: 250px !important;
    left: 225px !important;
}


.intercom-lightweight-app-launcher{
    bottom: 130px;
}