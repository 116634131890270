.d-btn {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.4px;
    line-height: 13px;
    text-align: center;
    border-radius: 3px;
    background-color: #1d3152;
    min-width: 100px;
    padding: 10px 15px;
    display: inline-block;
    margin-right: 10px;
    border: 0px;
}
.d-btn:hover,
.d-btn.green {
    background-color: #246b47;
    border-color: #246b47;
    cursor: pointer;
}
.d-btn.disabled {
    pointer-events: none;
    border-radius: 3px;
    opacity: 0.5;
}
.disable {
    opacity: 0.5;
    pointer-events: none;
}
.diable-button {
    background-color: #ababab !important;
}
.btndisableColor {
    opacity: 0.5;
}

@media (max-width: 767px) {
    .d-btn {
        margin: 0 !important;
        font-size: 10px;
    }
}
