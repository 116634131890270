/* utilities */
.secondary-text {
    color: #223150;
}
.uppercase {
    text-transform: uppercase;
}
.bold {
    font-weight: bold;
}
.gray-text {
    color: #898989;
}
.m-b-md {
    margin-bottom: 3.75rem;
}
.m-t-xs {
    margin-top: 0.9375rem;
}
.m-b-sm {
    margin-bottom: 2.5rem;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* form classes */
.form.login {
    padding: 1.875‬rem 0;
    width: 85%;
    max-width: 500px;
}

.form__heading {
    font-size: 2.3rem;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

.form__sub-heading {
    font-size: 1.25rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

@media only screen and (max-width: 87.5em) {
    .form.login {
        max-width: 440px;
    }
    .form__heading {
        font-size: 2.1875rem;
    }
    .form__sub-heading {
        font-size: 1rem;
        font-weight: 400;
    }
    .forgot-link {
        top: 0.3125‬rem;
    }
}

/* button classes */

.primary-btn {
    min-width: 10.625rem;
    border: 1px solid #898989;
    border-radius: 3px;
    color: #ffffff;
    display: inline-flex;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    align-items: center;
    padding: 10px 15px;
    font-size: 1.125rem;
    font-family: inherit;
    font-weight: bold;
    text-transform: capitalize;
    background-color: #898989;
    transition: all 0.5s ease-in-out;
    -webkit-box-shadow: -2.5px 4px 9px -5px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: -2.5px 4px 9px -5px rgba(0, 0, 0, 0.6);
    box-shadow: -2.5px 4px 9px -5px rgba(0, 0, 0, 0.6);
}

.primary-btn.out-line {
    background-color: transparent !important;
    color: inherit;
}

.primary-btn.out-line:active,
.primary-btn.out-line:focus {
    color: inherit;
}

.primary-btn.big {
    border: 2px solid #898989;
    font-size: 1.5rem;
    padding: 14px 18px;
    min-width: 13.125rem;
}

.primary-btn.green {
    border: 1px solid #3aaf50;
    background-color: #3aaf50;
}

.primary-btn.green.outline,
.primary-btn.green span {
    color: #3aaf50;
}
.primary-btn.green.big {
    border: 2px solid #3aaf50;
}

.primary-btn:focus{
    outline: none;
}

@media only screen and (max-width: 87.5em) {
    .primary-btn {
        min-width: 7rem;
        padding: 0 10px;
        border-radius: 2px;
        height: 38px;
        font-size: 14px;
    }

    .primary-btn.big {
        height: 55px;
        padding: 0 18px;
        border-radius: 5px;
        font-size: 17px;
        min-width: 9rem;
    }
}
