// Chat Main Section
section.chat-box-sec {
    font-family: Montserrat;
    max-width: 400px;
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 3px 3px 16px 1px rgba(0, 0, 0, 0.18);
    margin-left: auto;
}

.LDA-max-width {
    max-width: 25% !important;
    display: flex !important;
    flex-wrap: nowrap;
}
.LDA-max-width-chatBox {
    max-width: 45% !important;
    display: flex !important;
    flex-wrap: nowrap;
}
.multiline-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Chat Header
.chat-box-header {
    display: flex;
    align-items: center;
    max-width: 100%;
    width: 100%;
    min-height: 77px;
    border-radius: 3px 3px 0 0;
    background: linear-gradient(135deg, #2c4a72 0%, #1d3152 100%);
    padding: 15px;

    & figure {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        gap: 15px;

        & h2 {
            color: #ffffff;
            font-size: 19px;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 21px;
            margin-bottom: 0px;
        }
        & p {
            color: #ffffff;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            margin-bottom: 0px;

            & span.status {
                color: #3aaf50;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 13px;
                margin-left: 10px;
            }
        }
    }
    & .chat-profile {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

// Chat Body
.chat-box-body {
    position: relative;
    height: 400px;
    padding: 10px 15px 10px 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0px auto;

    & .chat-bubble {
        position: relative;
        max-width: 80%;
        display: block;
        width: 100%;
        color: #1d3152;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        background-color: #f6f7f8;
        box-shadow: 2px 2px 4px 0 #e0e0e0;
        padding: 10px 15px;
        border-radius: 15px;
        margin-left: 10px;
        word-break: break-word;
        overflow-wrap: anywhere;
    }
    & .chat-bubble::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-right: 13px solid #f6f7f8;
        border-bottom: 7px solid transparent;
        left: -7px;
        bottom: -5px;
        transform: rotate(-46deg);
    }
}
.chat-box-input {
    position: relative;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 100%;
    width: 100%;
    padding-right: 10px;

    & input {
        width: calc(100% - 100px);
        padding: 0px 10px;
        height: 100%;
        outline: none !important;
        resize: none;
        font-size: 14px;
        border: 0px;
    }
    & input::placeholder {
        color: #a7b9c5;
        font-size: 14px;
        font-style: italic;
        letter-spacing: 0;
        line-height: 16px;
    }

    & div {
        width: 23px;
        text-align: left;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
        color: #a7b9c5;
        margin-right: 0px;
    }
}

.chat-box-footer {
    position: relative;
    height: 20px;
    color: #a7b9c5;
    font-family: Montserrat;
    font-size: 8px;
    letter-spacing: 0;
    line-height: 8px;
    text-align: center;
    background-color: #22385b;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.quick-msg {
    position: relative;
    width: 100%;
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 0px;

    & span.q-bubble {
        position: relative;
        display: inline-block;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 11px;
        border-radius: 50px;
        background-color: #246b47;
        padding: 8px 12px;
        margin: 0px 5px 5px;

        strong {
            display: inline-block;
            margin-right: 5px;
            font-weight: normal !important;
        }
        svg {
            font-size: 8px;
        }
        svg:hover {
            cursor: pointer;
        }
    }
}
section.chat-box-sec {
    & ::-webkit-scrollbar {
        width: 0px;
        border-radius: 5px;
    }

    /* Track */
    & ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    & ::-webkit-scrollbar-thumb {
        background: #203557;
    }

    /* Handle on hover */
    & ::-webkit-scrollbar-thumb:hover {
        background: #063c92;
        border-radius: 5px;
    }
}

@media (max-width: 1199px) {
    .chat-box-body {
        & .chat-bubble {
            font-size: 12px;
            text-align: left !important;
        }
    }
    section.chat-box-sec {
        margin-left: unset;
    }
}

@media (max-width: 767px) {
    section.chat-box-sec {
        margin: 0 auto !important;
    }
    .cbt-buttons {
        text-align: center;
        justify-content: center;
        margin-top: 15px;
        margin-bottom: 20px;
    }
}
.status-indicator {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    z-index: 1;
}
.status-online {
    background-color: #3aaf50;
}
.logo-container {
    display: flex;
    place-content: end;
}