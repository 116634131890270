* {
    outline: none !important;
}
$d-clr: #1d3152;
$bdr-clr: #a7b9c5;
$dl-clr: #748997;

.t-bold {
    color: #1d375f;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
}

.LDA-font {
    font-family: Montserrat !important;
}

.LDA-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.LDA-flex > svg {
    font-size: large !important;
    margin-right: 2px;
}

.t-thin {
    font-size: 12px;
    font-weight: 500;
    color: $dl-clr;
}

section.awm-chat-form {
    position: relative;
    text-align: left;
    width: 100%;

    & .row {
        // align-items: flex-end;
    }

    & h5 {
        color: #748997;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 13px;
        margin-bottom: 7px;
    }

    & h3 {
        color: $dl-clr;
        font-size: 19px;
        font-weight: bolder;
        line-height: 1.5;
        font-family: "Montserrat";
        margin-bottom: 20px;

        input.CompNameInput {
            position: relative;
            border: 0px !important;
            box-sizing: border-box;
            width: 396px;
            border-bottom: 1px solid #a7b9c5 !important;
            color: #1d3152 !important;
            font-family: Montserrat;
            font-size: 19px;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 23px;
            padding: 3px 0;
            outline: none !important;
            box-shadow: none !important;
        }
        input.CompNameInput + p {
            width: 396px;
        }
    }
    & form.main-form {
        margin-left: 45px;
    }
    & form {
        margin-top: 35px;
        margin-right: 20px;

        & .form-head,
        .form-intro {
            position: relative;
            line-height: 1;
            margin-bottom: 30px;

            & p {
                margin-bottom: 8px;
            }
        }

        & small {
            color: #748997;
            font-family: Montserrat;
            font-size: 10px;
            letter-spacing: 0;
            line-height: 11px;
            margin-bottom: 17px;
            display: block;
        }

        & .ant-checkbox + span {
            padding-right: 0px;
            padding-left: 2px;
            color: #a7b9c5;
            font-family: Montserrat;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 11px;
        }
        & label {
            padding-right: 0px;
            padding-left: 2px;
            color: #a7b9c5;
            font-family: Montserrat;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 11px;
            margin-right: 34px;

            & input[type="checkbox"] {
                width: 9px;
                height: 9px;
                background-color: #fff;
                border: 1px solid #1d3152;
                border-radius: 0px;
                margin-right: 3px;
                background-color: #246b47 !important;
                border-color: #246b47;
            }
        }

        & .ant-checkbox-group-item {
            margin-right: 35px;
            align-items: center;
        }

        & .ant-checkbox {
            top: 0;
        }

        & lable {
            display: block;
            color: #1d3152;
            font-family: Montserrat;
            font-size: 10px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 11px;
            margin-bottom: 10px;
        }

        & textarea,
        input.display-message {
            color: #1d3152;
            font-family: Montserrat;
            font-size: 10px;
            letter-spacing: 0;
            line-height: 11px;
            width: 100%;
            height: 50px;
            resize: none;
            padding: 7px 10px;
            border: 1px solid #a7b9c5;
            border-radius: 3px;
            background-color: #ffffff;
        }
        input.display-message {
            height: 30.5px;
        }
    }
    .ant-checkbox-inner {
        width: 9px;
        height: 9px;
        background-color: #fff;
        border: 1px solid #1d3152;
        border-radius: 1px;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #246b47;
        border-color: #246b47;
    }

    .form-intro {
        margin-bottom: 10px !important;
    }

    & .form-fields {
        position: relative;
        margin-bottom: 15px;

        & textarea,
        & input,
        & .select-attachment,
        .accordion-button {
            width: 100%;
            min-height: 40px;
            line-height: 1.3;
            font-size: 12px;
            color: $d-clr;
            box-sizing: border-box;
            border: 1px solid $bdr-clr;
            border-radius: 3px;
            background-color: #ffffff;
            padding: 10px;
        }

        & textarea {
            height: 110px;
            line-height: 1.3;
            resize: none;
        }

        & textarea:focus {
            outline: none !important;
            border: 1px solid #1d3152 !important;
            border-radius: 3px !important;
            background-color: #ffffff !important;
            box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.18) !important;
        }
    }

    & .select-attachment {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 100%;
        width: 100%;

        & svg {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            transform: rotate(315deg);
        }

        & select {
            width: calc(100% - 22px);
        }
    }
}

.accordion-item {
    border-color: $bdr-clr !important;
    margin-bottom: 20px;
    outline: none !important;
    border: 1px solid $bdr-clr !important;
    border-radius: 4px !important;
    transition: 0.4s;

    // border-bottom-width: 0px;
    & button.accordion-button:focus {
        outline: 0 !important;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
        border-bottom: 0px !important;
    }

    & button.accordion-button {
        background-color: white;
        padding: 7px 10px 7px !important;
        border-bottom: 0px !important;
        color: #1d3152;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 13px;

        & svg {
            width: 18px;
            height: 18px;
            margin-right: 10px;
        }

        & img {
            position: relative;
            width: 18px;
            height: 18px !important;
            object-fit: contain !important;
            height: auto;
            margin-right: 10px;
        }
    }

    & button.accordion-button::after {
        color: $d-clr !important;
        filter: brightness(0) invert(0);
        width: 12px;
        height: 12px;
        background-size: contain;
    }

    & button.accordion-button.collapsed {
        outline: none !important;
    }

    & .accordion-header,
    & button.accordion-button {
        outline: none !important;
        // border-top: 1px solid $bdr-clr !important;
        border-radius: 3px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-radius: 3px !important;
    }

    // & .accordion:active,
    // & .accordion-collapse.show {
    //     // outline: none;
    //     // border-color: #1D3152;
    //     // border: 1.8px solid #1D3152;
    //     // box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.18);
    // }

    & .accordion-button::after {
        transform: rotate(-90deg);
    }

    & .accordion-button:not(.collapsed) {
        box-shadow: none;
    }

    & .accordion-button:not(.collapsed)::after {
        transform: rotate(0deg);
    }

    button.accordion-button .ah_note {
        flex-flow: column;
        text-align: left;
        align-items: flex-start;
        line-height: 1.5;
    }

    .ah_note small {
        display: block;
        margin-bottom: 5px;
        display: block;
    }

    & .accordion-body {
        padding: 00px 40px 17px;
    }

    & form {
        margin-top: 0px !important;
    }
}

.accordion-button:not(.collapsed)::after {
    width: 14px;
    height: 14px;
    background-size: contain;
}
.attached-icon svg {
    transform: rotate(315deg);
}

label.custom-file-upload input {
    display: none;
}

.custom-file-upload,
button.accordion-button {
    width: 100%;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
}

.t-thin.accordion-body form {
    margin: 0;
    line-height: 1;
}

.form-check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    line-height: 1;

    & label.form-check-label {
        width: 100%;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 13px;
    }

    & .form-check-input[type="radio"] {
        position: relative;
        top: -3px;
    }
}

.t-thin.accordion-body form {
    margin: 0;
    line-height: 1;
}

.cbt-buttons {
    margin-bottom: 30px;
    display: flex;
    justify-content: left;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    max-width: 400px;
    width: 100%;
    margin-left: auto;
}

button.btn-add-new {
    padding: 0;
    color: #246b47;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
    text-decoration: underline;
    outline: none !important;
}

button.btn-add-new:focus,
button.btn-add-new:active,
button:focus:not(:focus-visible) {
    outline: none !important;
    box-shadow: none;
}

.add-help-file.modal {
    & .modal-dialog {
        max-width: 380px;
    }
    & .modal-header {
        padding: 15px 15px 0px 0;
        border: 0;
    }

    & .modal-body {
        padding: 0px 30px 10px 30px;

        & h3 {
            color: #1d3152;
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 17px;
            margin-bottom: 20px;
        }

        & lable {
            color: #748997;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 13px;
        }

        & input {
            width: 100%;
            height: 30px;
            display: block;
            border: 1px solid #a7b9c5;
            border-radius: 3px;
            background-color: #ffffff;
            outline: none;
            padding: 5px 8px;
        }

        & input[type="file"] {
            color: #1d3152;
            font-family: Montserrat;
            font-size: 10px;
            letter-spacing: 0;
            line-height: 12px;
        }
    }

    & .modal-footer {
        // padding: 0;
        border: 0;
        justify-content: center;
        padding: 20px 10px 30px 10px;
    }
}

.ant-checkbox-group {
    display: block;
    margin-bottom: 10px;
}
span.ant-radio + * {
    color: #1d3152;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    align-items: center;
}
.ant-radio-wrapper {
    line-height: 1;
    align-items: baseline;
}
label.ant-radio-wrapper.ant-radio-wrapper-checked span {
    color: #1d3152;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
}
.ant-radio-inner {
    width: 12px;
    height: 12px;
    outline: none !important;
}
.ant-radio-inner::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    background-color: #233a5e !important;
    width: 6px;
    height: 6px;
    outline: none !important;
    border-radius: 10px;
}
.ant-radio-checked .ant-radio-inner {
    border-color: #223a5e;
    outline: none !important;
}
.accordion-item:focus,
.accordion-item:active,
.accordion-item:visited,
.accordion-item:target,
.accordion-flush .accordion-item:hover {
    outline: none !important;
    border: 1px solid #1d3152 !important;
    border-radius: 3px !important;
    background-color: #ffffff !important;
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.18) !important;
}
.ant-space.ant-space-vertical {
    gap: 6px !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    top: 1px;
    left: 0px;
    transform: rotate(45deg) scale(0.5) translate(-50%, -50%) !important;
}

.for-tabs-url {
    margin-top: 20px;

    & .ant-tabs-tab-btn {
        color: #748997;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 13px;
    }
    & .ant-tabs-tab-active {
        & .ant-tabs-tab-btn {
            color: #1d3152;
        }
    }

    & .ant-tabs-tab + .ant-tabs-tab {
        position: relative;
        margin-left: 15px;
        padding: 0 !important;
    }

    & .ant-tabs-tab {
        padding: 5px 0;
    }

    & .ant-tabs-nav-wrap {
        border: 0 !important;
    }

    & .ant-tabs-top > .ant-tabs-nav {
        margin-bottom: 0;
    }
    & .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
        display: none;
    }

    & .ant-tabs-tab::before {
        content: " ";
        position: absolute;
        background: #a7b9c5;
        width: 1px;
        height: 10px;
        right: -8px;
    }

    & .ant-tabs-nav-list .ant-tabs-tab:nth-child(2)::before {
        display: none !important;
    }
}
.msg-display {
    & input.invalid.CompNameInput {
        border-bottom: 1px solid #d40d00 !important;
        border-radius: 0px;
    }
    & .invalid {
        border: 1px solid #d40d00 !important;
        border-bottom: 1px solid #d40d00 !important;
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.18);
        color: #d40d00 !important;

        &::placeholder {
            color: #d40d00 !important;
        }
    }
    // position: relative;
    // display: flex;
    // flex-direction: column-reverse;

    // & p.required-error , &  textarea {
    //     border: 1px solid #D40D00 !important;
    //     border-radius: 3px;
    //     background-color: #FFFFFF;
    //     box-shadow: 2px 2px 4px 1px rgba(0,0,0,0.18);
    //     color: #D40D00 !important;

    //     &::placeholder{
    //         color: #D40D00 !important;;
    //     }
    // }
    & p.required-error {
        color: rgba(212, 13, 0, 0.7);
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 13px;
        text-align: right;
        margin: 0px;
        margin-top: 2px;
    }
}

// .accordion-item div.show{
//     // .accordion-item > .show < .accordion-item{
//     //     outline: none !important;
//     //     border: 1px solid #1D3152 !important;
//     //     border-radius: 3px !important;
//     //     background-color: #FFFFFF !important;
//     //     box-shadow: 2px 2px 4px 1px rgba(0,0,0,0.18) !important;
//     // }
// }
.ant-message {
    z-index: 9999;
}

.link:link,
.link:visited,
.link:hover,
.link:active {
    color: #000000 !important;
    text-decoration: none;
}

.parent_class:hover > .childClass {
    // font-weight: bold;
    color: #748997;
    cursor: pointer;
}

.wc-border-outline {
    border: 1px solid #d40d00 !important;
    box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 18%);
}

.pageURLBox {
    color: #1d3152 !important;
    font-family: Montserrat;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 11px;
    width: 100%;
    resize: none;
    padding: 7px 10px;
    border: 1px solid #a7b9c5;
    border-radius: 3px;
    background-color: #ffffff;
    display: flex;
    align-items: center;

    height: 30.5px;
}

.pageURLBox input {
    border: 0px !important;
    background-color: none !important;
    display: block;
    flex: 1;
}
.pageURLBox span {
    color: #a7b9c5;
}

.pageURLBox input:focus {
    box-shadow: none !important;
}

@media (max-width: 991px) {
    .cbt-buttons {
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    section.awm-chat-form {
        & form {
            margin-top: 15px;
            margin-right: 0px;
        }
    }
    section.awm-chat-form form {
        & .form-head {
            margin-bottom: 20px;
        }
    }
    section.awm-chat-form {
        & .form-fields {
            margin-bottom: 8px;
        }
    }
    .accordion-item {
        margin-bottom: 10px;
        text-align: left !important;
    }
    section.awm-chat-form,
    section.awm-chat-form .form-fields textarea {
        text-align: center !important;
    }
}
