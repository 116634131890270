section.webchat-form-sec {
    padding-top: 90px;
    padding-bottom: 90px;
    width: 100%;

    & .screen-title {
        margin-bottom: 30px;
        text-align: center;

        & img {
            width: 65px;
            height: 62px;
            display: inline-block;
            margin-bottom: 12px;
        }

        & h3 {
            margin-bottom: 5px;
            color: #1d3152;
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 22px;
        }
        & p {
            color: #1d3152;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 500;
        }
    }

    & .setup-form-sec {
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 3px 3px 16px 1px rgba(0, 0, 0, 0.18);

        & .setup-form {
            padding: 30px 75px;

            & .steps-action {
                text-align: center;
            }
        }
        & button.ant-btn {
            border-radius: 3px;
            background-color: #1d3152;
            border-color: #1d3152;
            font-size: 12px;
            font-weight: 600;
            padding: 10px 27px;
            line-height: 13px;
            height: auto;
        }
        & button.ant-btn:hover {
            background-color: #246b47;
            border-color: #246b47;
        }
        & .widget-install-sec .copy-code-sec {
            border: 1px solid #a7b9c5;
            border-radius: 3px;
            font-size: 14px;
            padding: 10px;
            position: relative;
            /* width */
            // & pre code{
            //     width: 5px;
            // }
            & pre {
                overflow-y: auto;
                max-height: 300px;
                margin-bottom: 0;
                padding-right: 5px;
                padding-bottom: 5px;
            }

            & pre::-webkit-scrollbar {
                width: 10px;
                height:10px;
            }

            /* Track */
            & pre::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            /* Handle */
            & pre::-webkit-scrollbar-thumb {
                background: #1d3152;
            }

            /* Handle on hover */
            & pre::-webkit-scrollbar-thumb:hover {
                background: #616e83;
            }
            & .copy-tooltip button.copy-code-sec-btn {
                opacity: 0.5;
                background-color: #fff;
                color: #909ba1;
                font-weight: 400;
                border-radius: 5px;
                border-color: #909ba1;
                padding: 5px;
                line-height: normal;
            }
            & .copy-tooltip button.copy-code-sec-btn:hover{
                background-color: #1d3152;
                color: #fff;
                border-color: #1d3152;
            }
            .copy-tooltip {
                position: absolute;
                right: 40px;
                bottom: 40px;
              }
              
              .copy-tooltip .copy-tooltiptext {
                visibility: hidden;
                background-color: #1d3152;
                color: #fff;
                text-align: center;
                border-radius: 2px;
                padding: 5px 10px;
                position: absolute;
                z-index: 1;
                left: 50%;
                font-size: 11px;
                width: max-content;
                top: -33px;
                transform: translate(-50%, 0);
              }
              
              .copy-tooltip .copy-tooltiptext::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #1d3152 transparent transparent transparent;
              }
              
              .copy-tooltip:hover .copy-tooltiptext {
                visibility: visible;
              }
        }
        & .steps-content {
            margin-bottom: 30px;
        }

        & button.ant-btn:disabled {
            background: #a7b9c5;
            color: #fff;
            border-color: #a7b9c5;
        }
        & button.ant-btn.get-help-btn {
            padding: 10px 18px;
        }
    }
}
.form-field {
    text-align: left;
    margin-bottom: 30px;

    & label {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 5px;
        color: #1d3152;
        display: block;
        width: 100%;
    }

    & input {
        display: block;
        width: 100%;
        border: 1px solid #a7b9c5;
        border-radius: 3px;
        padding: 3px 10px 5px;
    }
    & .invalid {
        border-color: red;
    }
    & .required-error {
        color: red;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
    }
    & ::placeholder {
        color: #748997 !important;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
    }
    &:last-child {
        margin-bottom: 0px;
    }
}
.LDA-fontFamily {
    font-family: Montserrat;
}
.ant-steps {
    position: relative;
    overflow: hidden;
    justify-content: space-between;
    margin-bottom: 40px;

    & .ant-steps-item-content {
        display: block;
    }
    & .ant-steps-item {
        position: static;
        padding: 0px !important;
        flex: inherit;
    }
    & .ant-steps-item-title {
        position: static;
        padding: 0px;
        color: #a7b9c5 !important;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
    }
    & .ant-steps-item-title::after {
        left: 0;
        top: 21px;
        height: 2px;
        background-color: #1d3152 !important;
    }
    & .ant-steps-item-icon {
        position: relative;
        z-index: 9;
        height: 42px;
        width: 42px;
        border: 1.4px solid #1d3152 !important;
        background: #ffffff;
        opacity: 1;
        border-radius: 50%;
        margin-right: 0px;
        line-height: 37px;
        margin-bottom: 40px;
    }
    & .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
    }
    & .ant-steps-item-active span.ant-steps-icon {
        font-size: 0;
        height: 21px;
        width: 21px;
        background-color: #246b47;
        display: block;
        border-radius: 50%;
    }
    & .ant-steps-item:nth-child(3) {
        text-align: right;
    }
    & .ant-steps-item:nth-child(2) {
        text-align: center;
    }
    & .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
        color: #1d3152;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
    }
    & .ant-steps-item .ant-steps-item-active .ant-steps-item-title {
        color: #1d3152 !important;
        font-size: 18px;
        font-weight: bold;
    }
    & .ant-steps-item-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    & .ant-steps-item:nth-child(2) .ant-steps-item-container {
        align-items: center;
    }
    & .ant-steps-item:nth-child(3) .ant-steps-item-container {
        align-items: flex-end;
    }
    & .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
        background-color: #246c47;
    }
    & .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
        line-height: 31px;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    & .ant-steps-item-finish span.ant-steps-icon svg {
        color: #fff;
    }
    & .ant-steps-item-active .ant-steps-item-content .ant-steps-item-title {
        color: #1d3152 !important;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: bold;
    }
}

.setup-form .steps-content {
    & h6 {
        color: #1d3152;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 3px;
        text-align: left;
    }
    & p {
        color: #748997;
        font-family: Montserrat;
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 5px;
        text-align: left;
    }
    .bottom-text {
        margin-top: 5px;
    }
}
.checkbox-group {
    display: grid;
    // grid-template-columns: 3fr 3fr 3fr;
    grid-template-columns: 1.5fr 1.6fr 1fr;
    text-align: left;
    margin-top: 20px;

    & input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        background: url('data:image/svg+xml;utf8,<svg stroke="rgb(36 107 71)" fill="rgb(36 107 71)" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path></svg>');
        height: 22px;
        width: 22px;
        background-size: cover;
        background-position: center;
        cursor: pointer;
        margin-right: 6px;
        margin-bottom: 2px;
    }
    & input[type="checkbox"]:checked {
        background: url('data:image/svg+xml;utf8,<svg stroke="rgb(36 107 71)" fill="rgb(36 107 71)" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>');
        background-position: center;
        box-shadow: none;
        background-size: cover;
    }

    & svg {
        width: 22px;
        height: 22px;
        color: #246b47;
        margin-right: 6px;
    }

    & label {
        color: #1d3152;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
        display: flex;
        cursor: pointer;
    }
    // & label:nth-child(3n+2) {
    //     justify-content: center;
    // }
    // & label:nth-child(3n+3) {
    //     justify-content: flex-end;
    // }
}
@media (max-width: 991px) {
    .ant-steps .ant-steps-item-title,
    .ant-steps .ant-steps-item-active .ant-steps-item-content .ant-steps-item-title {
        font-size: 12px !important;
    }
}
@media (max-width: 767px) {
    section.webchat-form-sec {
        padding-top: 40px;
    }
    .ant-steps {
        & .ant-steps-item:nth-child(2) .ant-steps-item-container {
            align-items: center;
        }
        & .ant-steps-item:nth-child(3) .ant-steps-item-container {
            align-items: flex-end;
        }
    }
    .checkbox-group label {
        font-size: 14px;
    }
    .ant-steps .ant-steps-item-title,
    .ant-steps .ant-steps-item-active .ant-steps-item-content .ant-steps-item-title {
        font-size: 12px;
    }
}
@media (max-width: 575px) {
    .ant-steps-item-tail::after {
        display: none;
    }
    .ant-steps .ant-steps-item-container {
        justify-content: center;
        align-items: center;
    }
    .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon,
    .ant-steps .ant-steps-item {
        margin-bottom: 10px;
    }
    .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
        min-height: auto;
    }
    .ant-steps .ant-steps-item:nth-child(3) .ant-steps-item-container,
    .setup-form .steps-content h6,
    .setup-form .steps-content p {
        text-align: center !important;
        align-items: center !important;
    }
    section.webchat-form-sec .setup-form-sec .setup-form {
        padding: 30px 35px;
    }
    .checkbox-group {
        display: block;
    }
    .checkbox-group label,
    .checkbox-group label:nth-child(3n + 2),
    .checkbox-group label:nth-child(3n + 3),
    .setup-form .steps-content p {
        justify-content: center !important;
    }
    .ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
    .ant-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
        margin: 0 auto;
    }
    .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
        text-align: center !important;
        margin: 0 auto 10px auto;
    }
    section.webchat-form-sec .row {
        width: 90%;
        margin: 0 auto;
    }
    section.webchat-form-sec .setup-form-sec button.ant-btn {
        padding: 10px 10px;
    }
}