.att-btn {
    & input{
        display: none;
    }
    & label {
        border-radius: 3px;
        background-color: #1D3152;
        padding: 10px 33px;
        color: #FFFFFF;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        text-align: center;
        cursor: pointer;
        transition: all ease-in-out 0.3s;
    }
    & label:hover{
        background-color: #246B47;
        border-color: #246B47;
    }
}

.as-complete-modal{
    &  h3 {
        color: #1D3152;
        font-family: Montserrat;
        font-size: 19px;
        font-weight: 800;
    }
    & p {
        color: #748997;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0px;
    }
    & .modal-header {
        border: 0;
        padding: 0px;
    }
    & .modal-footer {
        border: 0;
        justify-content: center;
        padding: 0px;
    }
    & button {
        border-radius: 3px;
        background-color: #1D3152;
        padding: 6px 33px;
        color: #FFFFFF;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        text-align: center;
        transition: all ease-in-out 0.3s;
    }
    & button:hover{
        background-color: #246B47;
        border-color: #246B47;
        color: #FFFFFF;
    }
    & button.btn-primary:disabled {
        border-radius: 3px;
        background-color: #A7B9C5;
        padding: 6px 21px;
        color: #FFFFFF;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        text-align: center;
        border-color: #A7B9C5;
        opacity: 1;
    }

    & button.btn-close {
        padding: 0;
        background-color: transparent;
        color: #748997;
        opacity: 1;
        margin: 0;
        position: absolute;
        right: 10px;
        top: 10px;
        transition: all ease-in-out 0.3s;
        filter: invert(62%) sepia(26%) saturate(280%) hue-rotate(
    161deg) brightness(81%) contrast(85%);
    }
    & button.btn-close:hover {
        filter: invert(13%) sepia(72%) saturate(806%) hue-rotate(185deg) brightness(91%) contrast(89%);
    }
    & .modal-content {
        border: 1px solid #DEE4E7;
        border-radius: 10px;
        background-color: #FFFFFF;
        box-shadow: 6px 6px 16px 1px rgba(0,0,0,0.18);
        //height: 150px;
        // padding: 22px 50px;
        padding: 40px;
    }
    
    & .modal-dialog.modal-dialog-centered {
        max-width: 560px;
    }
    & .modal-body {
        padding: 0px;
        // margin-bottom: 20px;
    }
    
}
.auto-comp-modal {
    & .modal-content {
        border-radius: 10px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.18), 0 -2px 6px 4px rgba(0,0,0,0.05);
        min-height: 170px;
        border: 0;
        border-top: 12.39px solid #246B47;
        padding: 25px 30px;
    }
    & .modal-dialog.modal-dialog-centered {
        max-width: 464px;
    }
    & .checkicon-sec {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    & .checkicon-sec span {
        border: 3px solid #C6D0D5;
        height: 20.82px;
        width: 20.81px;
        display: block;
        border-radius: 50%;
    }
    & .checkicon-sec svg {
        font-size: 36px;
        color: #246B47;
        position: absolute;
        top: -9px;
        line-height: normal;
        height: auto;
        padding-left: 4px;
    }
    & p{
        color: #344C60;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }
    & button {
        color: #638CCF;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        background: transparent;
        padding: 0;
        border: 0;
        text-decoration: underline;
    }
    & button:hover {
        background: transparent;
        color: #246B47;
    }
    & button:focus{
        color: #638CCF;
        background: transparent;
        outline: none !important;
        box-shadow: none;
    }
    & .modal-footer {
        margin-top: 6px;
    }
}

.greenBtn{
    background-color: #246B47 !important;
    border-color: #246B47 !important;
}

.resolveModalFooter{
    margin-top: 35px;
}