.auto-sec {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  // min-height: 100vh;
  height: calc(100vh - 75px);
  overflow: scroll;
  overflow-x: hidden;
  
  & .left-sidebar {
    position: relative;
    display: block;
    min-width: 300px;
    max-width: 300px;
    overflow-y: scroll;
    // min-height: 100vh;
    // height: 100%;
    background-color: #f6f7f8;
    box-shadow: 0px 0px 20px 0px #00000033;
    text-align: left;
    padding: 20px 20px 20px 28px;
    transition: transform 0.6s ease-in-out;
    transition-delay: 0s;
    z-index: 10;
  }

  & .inner-sec {
    width: calc(100% - 330px);
  }

  & .inner-sec-w-side {
    width: 100%;
  }
}
.left-side-hamburg {
  position: absolute;
  top: 10px;
  right: -54px;
  color: #1d3152;
  border: 1px solid #1d3152;
  background-color: #ffffff;
  width: auto;
  padding: 4px;
  border-radius: 5px;
  font-size: 15px;
  z-index: 10;
  display: none;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1199px) {
  .auto-sec .left-sidebar {
      width: 290px;
  }
  .auto-sec .inner-sec {
      width: calc(100% - 290px);
  }

  .left-side-hamburg {
    display: flex;
  }
  .auto-sec {
    & .left-sidebar {
      position: absolute;
      width: 250px !important;
      transition: 0.5s;
      margin-left: -270px;
      // transform: translateX(-250px);
      // display: none;
    }
  }
  .auto-sec {
    & .inner-sec {
      width: calc(100%) !important;
      // transition: transform 0.6s ease;
      // transition-delay: 0s;
      // transform: translateX(-0px);
    }
  }
  .left-sidebar.show{
    margin-left: 0px;
  }
  // .left-sidebar.show + .inner-sec{
  //   width: calc(100% - 250px) !important;
  // }

  .auto-sec {
    & .left-sidebar {
      width: 250px;
    }

    & .inner-sec {
      width: calc(100% - 250px);
    }

    & .inner-sec-w-side {
      width: calc(100%);
      margin-left: unset;
    }
  }

}
@media (max-width: 1024px) {
  .auto-sec {
    & .inner-sec-w-side {
      width: calc(100%);
      margin-left: auto;
    }
  }
}
@media (max-width: 991px) {
  
}
@media (max-width: 767px) {
  
}

.automation-inbox-switch {
  color: #ffffff !important;
  text-transform: none !important;
  text-decoration: none !important;
  font-size: 13px !important;
  border: 1px solid #cdc9c9;
  background-color: #ffffff !important;
  border-radius: 10px;
  display: flex;
  & a {
    font-family: Montserrat SemiBold !important;
    width: 100%;
    font-size: 12px !important;
    background-color: #ffffff;
    align-items: center;
    border-radius: 10px;
    color: #748997 !important;
    text-decoration: none !important;
    display: flex;
    justify-content: center;
  }
  & .active{
    background-color: #1d3152;
    color: #ffffff !important;
  }
}

.automation-inbox-switch-wrapper{
  width: 100%;
  margin-right: 10px;
}

.filter-inbox-wrapper{
  font-size: 12px !important;
  border: 1px solid #cdc9c9 !important;
  border-radius: 10px !important;
  width: 100%;
  display: flex;
  
  & .ant-select {
    width: 100%;
    margin: 0;
    padding-left: 17px;
  }
 & .ant-select-selector{
  height: 0;
 }
}

.inbox-sidebar-wrapper .ant-select-selector span.ant-select-selection-item{
  font-size: 12px;
}

.mb-0{
  margin-bottom: 0 !important;
}
