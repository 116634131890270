.main-heading {
    height: 27px;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 27px;
}
.color-acive {
    color: #1d3152;
    box-shadow: 3px 3px 16px 1px rgb(0 0 0 / 18%);
}
.color-in-acive {
    border: 1px solid #a8b5c2;
    border-radius: 3px;
    color: #a8b5c2;
}
.wc-card-top {
    margin-top: 67px;
}
.wc-card-bottom {
    margin-bottom: 67px;
}
.card-icon {
    height: 57px;
    width: 62px;
    margin-bottom: 19px;
}
.wc-card-end {
    justify-self: flex-end;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 32px;
    margin-top: auto;
    display: flex;
}
.heading {
    font-family: Montserrat;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
}

.content {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}
.wc-card {
    display: flex;
    text-align: center !important;
    flex-direction: column;
    align-items: center;
    padding: 35px 60px;
    margin-left: 35px;
    margin-right: 35px; 
    width: 350px;
    height: 370px;
}

.wc-mt-32 {
    margin-top: 32px;
}

.wc-width {
    width: 171px;
}
.wc-width2 {
    width: 276px;
}

.line {
    box-sizing: border-box;
    height: 1px;
    width: 73px;
    margin-top: 56px;
    margin-bottom: 52px;
    border: 1px solid #979797;
}

.wc-btn {
    height: 31px;
    width: 166px;
}

.wc-card-end-btn {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.all-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.multiline-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400px;
}
.margin-0 {
    margin: 0 !important;
}
.padding-0 {
    padding: 0;
}
.ellipses {
    max-width: 226px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.status-title {
    font-size: 13px;
    color: #1d3152;
    font-weight: 700;
    line-height: normal;
    font-family: Montserrat;
}
.text {
    font-family: Montserrat;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 0;
}
.font-14 {
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
}
.page-header {
    font-size: 30px !important;
    padding-bottom: 15px;
}
.font-weight-500 {
    font-weight: 500;
}
.font-weight-900 {
    font-weight: 900 !important;
}
.margin-top-35 {
    margin-top: 35px;
}
.bold-content {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    color:#1d3152;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 0;
}
.wc-text-blue {
    color:#1d3152;
}
.card-component {
    padding-top: 65px;
    padding-bottom: 65px;
    text-align: center;
}
.space-between {
    justify-content: space-between;
}
.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-style {
    color: #246B47;
    margin-right: 10px;
    overflow: visible !important;
}
.stroke-width {
    stroke-width: initial;
}