.companyName {
    height: 20px !important;
    width: 200px !important;

    color: #a7b9c5;
    font-size: 17px !important;
    letter-spacing: 0 !important;
    font-weight: bold !important;
    line-height: 20px !important;
}

.automationName {
    height: 20px !important;
    width: 169px !important;

    font-weight: normal;
    color: #1d3152 !important;
    font-size: 17px !important;

    letter-spacing: 0 !important;
    line-height: 20px !important;
}
.wc-bold {
    font-weight: bold !important;
}

.wc-color {
    color: #1d3152 !important;
}

.multiline-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Montserrat Bold";
    width: 100%;
}
.fit-content {
    width: fit-content;
}